<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>

      <div class="form-item">
        <el-input v-model="formData.name" clearable placeholder="输入自提点">
        </el-input>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 内容区 start -->
    <section class="content-wrap">
      <!-- 列表 start -->
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @on-change-data="onHandleChangeData"
      ></table-list>
      <!-- 列表 end -->
    </section>
    <!-- 内容区 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import utilsTool from "@/utils/tools/tool";
import TableList from "../modules/table-list/index.vue";
import {
  getDeliveryListOfTdc,
  postDeliveryStatusOfTdc,
} from "@/api/tdc/delivery";
import { IS_MIN_APP_SHOW } from "../utils/enum/index";

export default {
  name: "DeliveryManage",
  components: {
    TableList,
  },
  data() {
    return {
      loadingData: false,
      utilsTool,
      formData: {
        name: "", // 自提点名称
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await getDeliveryListOfTdc(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax getDeliveryListOfTdc err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableData();
    },
    /**
     * 启用/禁用。二次确认
     */
    async onHandleChangeData(data) {
      const status =
        data.status === IS_MIN_APP_SHOW.no.value
          ? IS_MIN_APP_SHOW.yes.value
          : IS_MIN_APP_SHOW.no.value;
      try {
        const params = {
          id: data.id,
          status,
        };
        await postDeliveryStatusOfTdc(params);
        // 手动修改数据状态，减少一次接口请求
        const index = this.tableData.findIndex((item) => item.id === data.id);
        this.tableData[index].status = status;
        this.$message.success("操作成功");
      } catch (err) {
        console.log("ajax postDeliveryStatusOfTdc err", err);
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
