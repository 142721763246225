<template>
  <!-- table start -->
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="name"
      align="center"
      label="自提点名称"
      min-width="150"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="address"
      align="center"
      label="自提点地址"
      min-width="150"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="mobile"
      align="center"
      label="联系电话"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="freight_parameter"
      align="center"
      label="运费标准（元）"
      min-width="120"
    >
    </el-table-column>
    <el-table-column prop="status" align="center" label="状态" min-width="100">
      <template slot-scope="scope">
        <span>{{
          Object.values(IS_MIN_APP_SHOW).find(
            (item) =>
              item.value === (scope.row.status || IS_MIN_APP_SHOW.no.value)
          ).label
        }}</span>
      </template>
    </el-table-column>

    <el-table-column label="操作" min-width="150" align="center" fixed="right">
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEditData(scope.row)">{{
          scope.row.status === IS_MIN_APP_SHOW.no.value
            ? IS_MIN_APP_SHOW.yes.label
            : IS_MIN_APP_SHOW.no.label
        }}</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- table end -->
</template>

<script>
import { IS_MIN_APP_SHOW } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      IS_MIN_APP_SHOW,
    };
  },
  methods: {
    /**
     * 启用/禁用。二次确认
     */
    onHandleEditData(row) {
      const msg =
        row.status === IS_MIN_APP_SHOW.no.value
          ? IS_MIN_APP_SHOW.yes.label
          : IS_MIN_APP_SHOW.no.label;
      this.$confirm(`是否${msg}该自提点？`, "提示", {
        center: true,
        confirmButtonText: "确 认",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      })
        .then(() => {
          this.$emit("on-change-data", row);
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped></style>
