import axios from '../http';
const BASE_URL = process.env.VUE_APP_TDC_URL;

/**
 * 城市仓后台-自提点管理-列表
 * @param {*} params
 * @returns
 */
export function getDeliveryListOfTdc(params = {}) {
  return axios(
    {
      method: 'GET',
      url: `${BASE_URL}/tdc/store/delivery/list`,
      params
    }
  );
};

/**
 * 城市仓后台-自提点管理-列表-启用/禁用
 * @param {*} params
 * @returns
 */
export function postDeliveryStatusOfTdc(params = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/tdc/store/delivery/update/status`,
      data: params
    }
  );
};
