var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", align: "center", width: "100", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "name",
          align: "center",
          label: "自提点名称",
          "min-width": "150",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "address",
          align: "center",
          label: "自提点地址",
          "min-width": "150",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "mobile",
          align: "center",
          label: "联系电话",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "freight_parameter",
          align: "center",
          label: "运费标准（元）",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "status",
          align: "center",
          label: "状态",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      Object.values(_vm.IS_MIN_APP_SHOW).find(
                        (item) =>
                          item.value ===
                          (scope.row.status || _vm.IS_MIN_APP_SHOW.no.value)
                      ).label
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "操作",
          "min-width": "150",
          align: "center",
          fixed: "right",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEditData(scope.row)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        scope.row.status === _vm.IS_MIN_APP_SHOW.no.value
                          ? _vm.IS_MIN_APP_SHOW.yes.label
                          : _vm.IS_MIN_APP_SHOW.no.label
                      )
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }